import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import Dropzone from 'react-dropzone-uploader';

import {ExportToExcel2} from '../../../ExportToExcel2'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

import {
  getUsers as onGetUsers,
  addUser as onAddUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import FilterModal from "components/Common/FilterModal";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcı | Kupon ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();


  const [getFileSubMedia, setFileSubMedia] = useState([]);


  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const [getIsActive, setIsActive] = useState(false);
  const [getIsVip, setIsVip] = useState(false);
  const [getIsOfficial, setIsOfficial] = useState(false);
  // validation
  const [getCityID, setCityID] = useState(null);
  const [getDistID, setDistID] = useState(null);

  const [getFilterCheck, setFilterCheck] = useState(false);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);

  

  const [getFilterData, setFilterData] = useState([]);

  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: (contactEdit && contactEdit.NameSurname) || "",
      tel: (contactEdit && contactEdit.GsmNumber) || "",
      password: (contactEdit && contactEdit.Users_Password) || "",
      email: (contactEdit && contactEdit.MailAdress) || "",
      address:(contactEdit && contactEdit.Adress) || "",
      birthday:(contactEdit &&  moment(contactEdit.Users_Birthday).format('YYYY-MM-DD')) || "",

      
    },
    validationSchema: Yup.object({
 
      username: Yup.string(3).required("Kullanıcı adı Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {
        let getData = localStorage.getItem("authUser");

        const updateUser = {
          ID: contactEdit.ID,
          NameSurname: values.username,
          GsmNumber: values.tel,
          Users_Password: values.password,
          UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          MailAdress:values.email,
          District:getChoicegetDist,
          Adress:values.address,
          Users_IsActive: getIsActive,
          Vip_Users:getIsVip,
          Company_Official:getIsOfficial,
          Job_ID:getJobID,
          Company_ID:JSON.parse(getData).Company_ID,
          GenderID:getCategoryGenderID,
          City:getSelectCity,
          Users_Birthday:moment(values.birthday).format('YYYY-MM-DD'),
          City_ID: getCityID,
          Dist_ID: getDistID
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          NameSurname: values["username"],
          City: getSelectCity,
          MailAdress:values["email"],
          GsmNumber:values["tel"],
          Users_Password: values["password"],
          UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          District:getChoicegetDist,
          Adress:values["address"],
          Users_IsActive: getIsActive,
          Vip_Users:getIsVip,
          Company_Official:getIsOfficial,
          Job_ID:getJobID,
          Company_ID:JSON.parse(getData).Company_ID,
          Surname:values["surname"],
          GenderID:getCategoryGenderID,
          Users_Birthday: moment(values.birthday).format('YYYY-MM-DDTHH:mm:ssZ'),
          City_ID: getCityID,
          Dist_ID: getDistID
        };
        // save new user
        dispatch(onAddUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  
  const [getSelectCity, setSelectCity] = useState(null);

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [getFileName, setFileName] = useState('');


  const [getCategoryGenderID, setCategoryGenderID] = useState(null);
  
  const [getListDisct, setListDisct] = useState([]);
  const [getChoicegetDist, setChoicegetDist] = useState(null);

  

  const [getCategoryGender, setCategoryGender] = useState([]);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [getJobID, setJobID] = useState(null);
  const [getjob, setJob] = useState([]);
  const [getCompanyID, setCompanyID] = useState(null);
  const [getcompany, setCompany] = useState([]);
  const [getListCity, setListCity] = useState([]);

  const [getFilterModal, setFilterModal] = useState(false);

  
  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.UsersPhoto ? (
              <div className="avatar-xs">
                {cellProps.NameSurname != null ? <span className="avatar-title rounded-circle">
                  {cellProps.NameSurname.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.UsersPhoto}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Adı",
        accessor: "NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Firma",
        accessor: "Company.Company_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Şehir",
        accessor: "City",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Semt",
        accessor: "District",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Telefon Numarası",
        accessor: "GsmNumber",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Son Giriş Tarihi",
        accessor: "Last_Login_Date",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },


      {
        Header: "Aktif Mi?",
        accessor: "Users_IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },





      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

         

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getUsers_TypeKupon/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }


  const _getAuthData2 = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getJobSearchKupon/select/`)
      .then((res) => {
        setJob(res.data)
      })
  }
  const _getAuthData3 = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getCompanyKuponSearch/select/`)
      .then((res) => {
        setCompany(res.data)
      })
  }
   const _getAuthData4 = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getUsersGenderKupon/select/`)
      .then((res) => {
        setCategoryGender(res.data)
      })
  }


  const [getCategoryCompany, setCategoryCompany] = useState([]);

  const _getAuthDataCompany = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getCompanyKupon/select/`)
      .then((res) => {
        setCategoryCompany(res.data)
      })
  }

  useEffect(() => {
    _getAuthDataCompany()
    _getAuthData()
    _getAuthData2()
    _getAuthData3()
    _getAuthData4()
    let getData = localStorage.getItem("authUser");

    if (users && !users.length) {
      dispatch(onGetUsers(JSON.parse(getData).Company_ID));
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    _getCity()
    // setIsEdit(false);
  }, []);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };




  const _setChoiceCityLoading  = async(arg) => {


    setListDisct([])

    
    let filterData = getListCity.filter((x)=> x.iso2 == arg)

        
    await axios.get(`https://kuponifypremium.com.tr:8099/getDistrict/TR/${arg}`)
    .then((res) => {
      res.data.map((item, index) => (

        
        setListDisct(oldArray => [...oldArray, item] )

        //console.log("lsdfkl",item)

      ))
       })

  }

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);


    
    setCategoryID(user.Users_Type_ID)
    setCategoryGenderID(user.GenderID)
    setJobID(user.Job_ID)
    setCompanyID(user.Company_ID)
    setFileName(user.UsersPhoto)
   
      setIsActive(user.Users_IsActive)
   
  
      setIsVip(user.Vip_Users)
   
  
      setIsOfficial(user.Company_Official)

    
      setSelectCity(user.City)

      setChoicegetDist(user.District)
      setCityID(user.City_ID)
  
      _setChoiceCityLoading(user.City_ID)
  
      setDistID(user.Dist_ID)
  

    setIsEdit(true);


    toggle();
  };



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoicegetDist = (arg) => {

    setDistID(arg.target.value)
    let filterData = getListDisct.filter((x)=> x.id == arg.target.value)
    setChoicegetDist(filterData[0].name)

  };

  

  
  const _setChoiceCity  = async(arg) => {
    setListDisct([])

    setCityID(arg.target.value)

    let filterData = getListCity.filter((x)=> x.iso2 == arg.target.value)

    setSelectCity(filterData[0].name)
        
    await axios.get(`https://kuponifypremium.com.tr:8099/getDistrict/TR/${arg.target.value}`)
    .then((res) => {
      res.data.map((item, index) => (
        setListDisct(oldArray => [...oldArray, item] )
      ))
       })

  }

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }
  const _setChoiceCategoryGender = arg => {
    setCategoryGenderID(arg.target.value)
  }


  const _setChoiceJob = arg => {
    setJobID(arg.target.value)
  }


  const _setChoiceCompany = arg => {
    setCompanyID(arg.target.value)
  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const _getCity= async (city,CN) => {
    try {
  
       await axios.get(`https://kuponifypremium.com.tr:8099/getCity/TR`)
    .then((res) => {
  
      let newCityList = res.data.sort(function (a, b) {
        var atitle = a.name;
        var btitle = b.name;
        var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
        if (atitle.length === 0 || btitle.length === 0) {
            return atitle.length - btitle.length;
        }
        for (var i = 0; i < atitle.length && i < btitle.length; i++) {
            var ai = alfabe.indexOf(atitle[i].toUpperCase());
            var bi = alfabe.indexOf(btitle[i].toUpperCase());
            if (ai !== bi) {
                return ai - bi;
            }
        }
      })
  
      newCityList.map((item, index) => (
      
        setListCity(oldArray => [...oldArray, item] )

      ))
       })
       
      } catch (err) {
        console.log("_getCity",err)
      }
  }



  const [getExcelModal, setExcelModal] = useState(false);


  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"

  const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
    elt.NameSurname,
    elt.Last_Login_Date,
      ]);

    const ws = XLSX.utils.json_to_sheet(data2);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const exportToCSVDontLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "Son1AyGirisYapmayanlar"

  const data2 =  users.filter(x=> x.Last_Login_Date <=! moment().add(1, 'months').format('YYYY/MM/DD')).map(elt=> [
    elt.NameSurname,
    elt.Last_Login_Date,
      ]);

    const ws = XLSX.utils.json_to_sheet(data2);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }


  const exportToCSVLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "Son1AyGirisYapanlar"

  const data2 =  users.filter(x=> x.Last_Login_Date <= moment().add(1, 'months').format('YYYY/MM/DD')).map(elt=> [
    elt.NameSurname,
    elt.Last_Login_Date,
      ]);

    const ws = XLSX.utils.json_to_sheet(data2);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const exportToCSVLogin = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"

  const data2 =  users.filter(x=> x.Last_Login_Date != null).map(elt=> [
    elt.NameSurname,
    elt.Last_Login_Date,
      ]);

    const ws = XLSX.utils.json_to_sheet(data2);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const filterLogin = () => {
    const filteredUsers = users.filter(user => user.Last_Login_Date !== null)
   // const users = filteredUsers;
   setFilterCheck(true)
   setFilterData(filteredUsers)        
  }

  const filterDontLogin = () => {
    const filteredUsers = users.filter(user => user.Last_Login_Date == null)
    
    setFilterCheck(true)
   setFilterData(filteredUsers)        
  }

  const data2 =  users.map(elt=> [
    elt.NameSurname,
    elt.Last_Login_Date,
    ]);



  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Last_Login_Date <= moment().add(1, 'months').format('YYYY/MM/DD') && x.Company_ID)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Last_Login_Date <=! moment().add(1, 'months').format('YYYY/MM/DD') && x.Company_ID)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 



  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Listesi" />

        
<FilterModal
handleSelectChangeCompany= {_onFilterClick}
       
        show={getFilterModal}
        getCategoryCompany={getCategoryCompany}
        onCloseClick={() => setFilterModal(false)}
     
      />



         
         

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={getFilterCheckModal == true ? getFilterData : getFilterCheck == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddUser={false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
               

                  <Modal  size="lg" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                      
                        <Col lg={6}>
                            
                          
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı İsim Soyisim</Label>
                              <Input
                                name="username"
                                label="username"
                                type="text"
                                placeholder="Kullanıcı İsim Soyisim Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            
                            <Col lg={6}>
                            <div className="mb-3">
                      <Label className="form-label">Kullanıcı Doğum Tarihi</Label>
                      <Input
                        name="birthday"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.birthday || ""}
                        invalid={
                          validation.touched.birthday && validation.errors.birthday ? true : false
                        }
                      />
                      {validation.touched.birthday && validation.errors.birthday ? (
                        <FormFeedback type="invalid">{validation.errors.birthday}</FormFeedback>
                      ) : null}
                   
                    </div>
                    </Col>

                    <Col lg={6}>

                    <div className="mb-3">
                              <Label className="form-label">Şehir Seçiniz</Label>
                              <Input
                                name="selectCity"
                                type="select"
                                className="form-select"
                                defaultValue={getCityID}
                                onChange={(text) => _setChoiceCity(text)}
                                value={
                                  getCityID
                                }>
                                {getListCity.map((item, index) => (
                                  <option value={item.iso2} key={item.name}>{item.name}</option>
                                ))}

                              </Input>
                           
                            </div>

                          
                            </Col>

                           { getSelectCity != null ? 
                            <Col lg={6}> 
                            <div className="mb-3">
                              <Label className="form-label">Semt Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getDistID}
                                onChange={(text) => _setChoicegetDist(text)}
                                value={
                                  getDistID
                                }>
                                {getListDisct.map((item, index) => (
                                  <option value={item.id} key={item.id}>{item.name}</option>
                                ))}

                              </Input>
                           
                            </div>


                         </Col> : null}


                            <div className="mb-3">
                              <Label className="form-label">Adres</Label>
                              <Input
                                name="address"
                                label="address"
                                type="text"
                                placeholder="Adres"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                    validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                                validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Email Adresi</Label>
                              <Input
                                name="email"
                                label="email"
                                type="text"
                                placeholder="Email Adresi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                       

                            <Col lg={6}>

                            <div className="mb-3">
                              <Label className="form-label">Cinsiyet Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryGenderID}
                                onChange={(text) => _setChoiceCategoryGender(text)}
                                value={
                                  getCategoryGenderID
                                }>
                                 <option value="">Lütfen Seçim Yapınız</option>
                                {getCategoryGender.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.GenderType}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            </Col>


         

                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Meslek Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getJobID}
                                onChange={(text) => _setChoiceJob(text)}
                                value={
                                  getJobID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>
                                {getjob.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Job_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            </Col>



                            
                         

                    
                         
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
