import React, { useEffect, useState, useRef } from "react";

import withRouter from "components/Common/withRouter";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormFeedback,
  Form,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import axios from 'axios';

import "jspdf-autotable";



import {
  getSector as onGetSector,
  addSector as onAddSector,
  updateSector as onUpdateSector,
  deleteSector as onDeleteSector,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kampanya Kodu | KUPON ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();


  const [getCategoryJob, setCategoryJob] = useState([]);
  const [getCategoryCompany, setCategoryCompany] = useState([]);
  const [getCategoryGender, setCategoryGender] = useState([]);

  const [getGroup, setGroup] = useState([]);
  const [getUsers, setUsers] = useState([]);
  const [getGroupUser, setGroupUser] = useState([]);

  const [getCampainData, setCampainData] = useState([]);

  const [getCampainID, setCampainID] = useState(null);

  
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed

    initialValues: {
      title: "",
   

    },
  
    
    onSubmit: values => {
      var localDate = moment().local();

      selectedUsers.map((item, index) => {

        const newUser = {
          Campain_ID: getCampainID,
          Users_ID:item,
          Created_DateTime:localDate,
          Campain_Code:  values.title
        }
  
        axios.post('https://kuponifypremium.com.tr:8099/addCampain_HistoryKupon/send', newUser).then(response => {
      
      })


    }) 

      selectedValuesGroup.map((item, index) => {

        let filterData = getGroupUser.filter((x)=> x.GroupType_ID == item)


        filterData.map((item, index) => {

          const newUser = {
            Campain_ID: getCampainID,
            Users_ID:item.Users_ID,
            Created_DateTime:localDate,
            Campain_Code:  values.title
          }
    
          axios.post('https://kuponifypremium.com.tr:8099/addCampain_HistoryKupon/send', newUser).then(response => {
        
        })
  

        })
       

      }) 
        

      selectedValuesVip.map((item, index) => {

        let filterData = getUsers.filter((x)=> x.ID == item)


        filterData.map((item, index) => {

          const newUser = {
            Campain_ID: getCampainID,
            Users_ID:item.ID,
            Created_DateTime:localDate,
            Campain_Code:  values.title
          }
    
          axios.post('https://kuponifypremium.com.tr:8099/addCampain_HistoryKupon/send', newUser).then(response => {
        
        })
  
        })
       

      }) 
        
      selectedValuesJob.map((item, index) => {

        let filterData = getUsers.filter((x)=> x.Job_ID == item)

        filterData.map((item, index) => {
        const newUser = {
          Campain_ID: getCampainID,
          Users_ID:item.ID,
          Created_DateTime:localDate,
          Campain_Code:  values.title
        }
  
        axios.post('https://kuponifypremium.com.tr:8099/addCampain_HistoryKupon/send', newUser).then(response => {
      
      })

   
    })

      }) 


      selectedValuesGender.map((item, index) => {

        let filterData = getUsers.filter((x)=> x.GenderID == item)


        filterData.map((item, index) => {

          const newUser = {
            Campain_ID: getCampainID,
            Users_ID:item.ID,
            Created_DateTime:localDate,
            Campain_Code:  values.title
          }
    
          axios.post('https://kuponifypremium.com.tr:8099/addCampain_HistoryKupon/send', newUser).then(response => {
        
        })
  

         
        })
       

      }) 

      selectedValuesCompany.map((item, index) => {

         let filterData = getUsers.filter((x)=> x.Company_ID == item)


        filterData.map((item, index) => {

          const newUser = {
            Campain_ID: getCampainID,
            Users_ID:item.ID,
            Created_DateTime:localDate,
            Campain_Code:  values.title
          }
    
          axios.post('https://kuponifypremium.com.tr:8099/addCampain_HistoryKupon/send', newUser).then(response => {
        
        })
  
         
        })
       

      }) 


     
        showToast(true)
        validation.resetForm();
     
    },
  });

  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Bilgileriniz Güncellendi"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const { users } = useSelector(state => ({
    users: state.contacts.sector,
  }));

  const [isEdit, setIsEdit] = useState(false);
  const [getCategory, setCategory] = useState([]);



  const [selectedValuesJob, setSelectedValuesJob] = useState([]);
  const [selectedValuesGender, setSelectedValuesGender] = useState([]);
  const [selectedValuesCompany, setSelectedValuesCompany] = useState([]);
  const [selectedValuesGroup, setSelectedValuesGroup] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedValuesVip, setSelectedValuesVip] = useState([]);


  const handleSelectChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesJob(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }


  
    
  const handleSelectChangeCompany = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesCompany(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }


  const handleSelectChangeGender = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesGender(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }


  

  const handleSelectChangeUsers = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedUsers(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }

  const handleSelectChangeGroup = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesGroup(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }


  const _getAuthData = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getUsersVipKupon/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }


  const _getGroupType = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getGroupTypeKupon/select/`)
      .then((res) => {
        setGroup(res.data)
      })
  }


  const _getAuthDataJob = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getJobKupon/select/`)
      .then((res) => {
        setCategoryJob(res.data)
      })
  }
  const _getAuthDataCompany = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getCompanyKuponSearch/select/`)
      .then((res) => {
        setCategoryCompany(res.data)
      })
  }
  const _getAuthDataGender= async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getUsersGenderKupon/select/`)
      .then((res) => {
        setCategoryGender(res.data)
      })
  }

  const _getGroupUser = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getGroupUserKupon/select/`)
      .then((res) => {
        setGroupUser(res.data)
      })
  }

  const _getUsers= async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getUsersKupon/select/`)
      .then((res) => {
        setUsers(res.data)
      })
  }

  const _getCampainData = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getCampainKupon/select/`)
      .then((res) => {
        setCampainData(res.data)
      })
  } 

  useEffect(() => {
    _getCampainData()
    _getGroupUser()
    _getUsers()
    _getGroupType()
    _getAuthData()
    _getAuthDataJob()
    _getAuthDataCompany()
    _getAuthDataGender()

    if (users && !users.length) {
     
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  


  const _setChoiceCategory = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesVip(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }

  const _setChoiceCampain = arg => {
    setCampainID(arg.target.value)
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);


  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Kullanıcının girdiği metne göre seçenekleri filtrele
    const filtered = getCategoryCompany.filter(option =>
        option.Company_Title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredOptions(filtered);
};


  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kampanya Kodu Tanımla" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                  <Row>
                    <Col xs={12}>

                    <div className="mb-3">
                              <Label className="form-label">Kampanya Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCampainID}
                                onChange={(text) => _setChoiceCampain(text)}
                                value={
                                  getCampainID
                                }>
                                {getCampainData.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Campain_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
               
                    <div className="mb-3">
                      {getCategory.length > 0 ? 
                      <Label className="form-label">Vip Kullanıcı Seçiniz</Label> : 
                       <Label className="form-label">Vip Kullanıcı Seçiniz - </Label> }
                        {getCategory.length > 0 ? <Input
                          name="vipUser"
                          type="select"
                          className="form-select"
                          multiple
                          onChange={(text) => _setChoiceCategory(text)}
                          value={selectedValuesVip} // Change this line
                        >
                         
                         { getCategory.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.NameSurname}</option>
                          )) }

                        </Input>: <a> Vip Kullanıcı Yok</a>}
                       
                      </div>

                      <div className="mb-3">
                              <Label className="form-label">Firma Seçiniz</Label>

                              <input
                type="text"
                className="form-control"
                placeholder="Arama yapın..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={selectedValuesCompany}
                                multiple
                                onChange={(text) => handleSelectChangeCompany(text)}
                                value={
                                  selectedValuesCompany
                                }>
                                {filteredOptions.length > 0 ? filteredOptions.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          )) :  getCategoryCompany.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>


                      <div className="mb-3">
                        <Label className="form-label">Cinsiyet Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          multiple
                          value={selectedValuesGender} // Burada selectedValues, bileşenin seçili değerlerini içeren bir durumdur
                          onChange={(event) => handleSelectChangeGender(event)}
                        >

                          {getCategoryGender.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.GenderType}</option>
                          ))}

                        </Input>
                      
                      </div>
                      
                      <div className="mb-3">
                        <Label className="form-label">Meslek Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          multiple
                          value={selectedValuesJob} // Burada selectedValues, bileşenin seçili değerlerini içeren bir durumdur
                          onChange={(event) => handleSelectChange(event)}
                        >

                          {getCategoryJob.map((item, index) => (
                            <option key={item.ID} value={item.Job_Title} >{item.Job_Title}</option>
                          ))}

                        </Input>
                       
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">Grup Seçiniz</Label>
                        <Input
                          name="group"
                          type="select"
                          className="form-select"
                          multiple
                          value={selectedValuesGroup} // Burada selectedValues, bileşenin seçili değerlerini içeren bir durumdur
                          onChange={(event) => handleSelectChangeGroup(event)}
                        >

                          {getGroup.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.GroupType_Name}</option>
                          ))}

                        </Input>
                       
                      </div>



                      <div className="mb-3">
                        <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="group"
                          type="select"
                          className="form-select"
                          multiple
                          value={selectedUsers} // Burada selectedValues, bileşenin seçili değerlerini içeren bir durumdur
                          onChange={(event) => handleSelectChangeUsers(event)}
                        >

                          {getUsers.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.NameSurname}</option>
                          ))}

                        </Input>
                       
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">Kampanya Kodu</Label>
                        <Input
                          name="title"
                          label="title"
                          type="text"
                          placeholder="Kampanya Kodunu Giriniz"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                          invalid={
                            validation.touched.title &&
                              validation.errors.title
                              ? true
                              : false
                          }
                        />
                        {validation.touched.title &&
                          validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </div>


                 


                    </Col>
                  </Row>
                  <Row>



                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                         TANIMLA
                        </button>
                      </div>
                    </Col>
                  </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
