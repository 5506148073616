import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname, Status } from "./contactlistCol";

import toastr from 'toastr'; // toastr kütüphanesini içe aktarın
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import Dropzone from 'react-dropzone-uploader';

import {ExportToExcel2} from '../../../ExportToExcel2'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import DateModal from "components/Common/DateModal";

import {
  getUsers as onGetUsers,
  addUser as onAddUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import FilterModal from "components/Common/FilterModal";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcı | Kupon ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();



  const [getIsActive, setIsActive] = useState(false);
  const [getIsVip, setIsVip] = useState(false);
  const [getIsOfficial, setIsOfficial] = useState(false);
  // validation
  const [getCityID, setCityID] = useState(null);
  const [getDistID, setDistID] = useState(null);

  const [getFilterCheck, setFilterCheck] = useState(false);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);

  

  const [getFilterData, setFilterData] = useState([]);

  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: (contactEdit && contactEdit.NameSurname) || "",
      tel: (contactEdit && contactEdit.GsmNumber) || "",
      password: (contactEdit && contactEdit.Users_Password) || "",
      email: (contactEdit && contactEdit.MailAdress) || "",
      address:(contactEdit && contactEdit.Adress) || "",
      birthday:(contactEdit &&  moment(contactEdit.Users_Birthday).format('YYYY-MM-DD')) || "",

      
    },
    validationSchema: Yup.object({
 
      username: Yup.string(3).required("Kullanıcı adı Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          NameSurname: values.username,
          GsmNumber: values.tel,
          Users_Password: values.password,
          UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          MailAdress:values.email,
          District:getChoicegetDist,
          Adress:values.address,
          Users_IsActive: getIsActive,
          Vip_Users:getIsVip,
          Company_Official:getIsOfficial,
          Job_ID:getJobID,
          Company_ID:getCompanyID,
          GenderID:getCategoryGenderID,
          City:getSelectCity,
          Users_Birthday:moment(values.birthday).format('YYYY-MM-DD'),
          City_ID: getCityID,
          Dist_ID: getDistID
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          NameSurname: values["username"],
          City: getSelectCity,
          MailAdress:values["email"],
          GsmNumber:values["tel"],
          Users_Password: values["password"],
          UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          District:getChoicegetDist,
          Adress:values["address"],
          Users_IsActive: getIsActive,
          Vip_Users:getIsVip,
          Company_Official:getIsOfficial,
          Job_ID:getJobID,
          Company_ID:getCompanyID,
          Surname:values["surname"],
          GenderID:getCategoryGenderID,
          Users_Birthday: moment(values.birthday).format('YYYY-MM-DDTHH:mm:ssZ'),
          City_ID: getCityID,
          Dist_ID: getDistID
        };
        // save new user
        dispatch(onAddUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  
  const [getSelectCity, setSelectCity] = useState(null);

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalLocation, setModalLocation] = useState(false);
  const [getLocaitonDetail, setLocaitonDetail] = useState([]);

  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [getFileName, setFileName] = useState('');


  const [getCategoryGenderID, setCategoryGenderID] = useState(null);
  
  const [getListDisct, setListDisct] = useState([]);
  const [getChoicegetDist, setChoicegetDist] = useState(null);

  

  const [getCategoryGender, setCategoryGender] = useState([]);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [getJobID, setJobID] = useState(null);
  const [getjob, setJob] = useState([]);
  const [getCompanyID, setCompanyID] = useState(null);
  const [getcompany, setCompany] = useState([]);
  const [getListCity, setListCity] = useState([]);

  const [getFilterModal, setFilterModal] = useState(false);
  const [selectedValuesCompany, setSelectedValuesCompany] = useState([]);
  const [getSelectedValuesCompanyDefault, setSelectedValuesCompanyDefault] = useState([]);

  
  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.UsersPhoto ? (
              <div className="avatar-xs">
                {cellProps.NameSurname != null ? <span className="avatar-title rounded-circle">
                  {cellProps.NameSurname.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.UsersPhoto}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Adı",
        accessor: "NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Firma",
        accessor: "Company.Company_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Şehir",
        accessor: "City",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Semt",
        accessor: "District",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Telefon Numarası",
        accessor: "GsmNumber",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Son Giriş Tarihi",
        accessor: "Last_Login_Date",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },


      {
        Header: "Aktif Mi?",
        accessor: "Users_IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
   
      {
        Header: "Vip Mi?",
        accessor: "Vip_Users",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },




      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickLocation(userData);
                }}
              >
                <i className="mdi mdi-checkbox-blank font-size-18" id="edittooltiplocation" />
                <UncontrolledTooltip placement="top" target="edittooltiplocation">
                  Konum
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getUsers_TypeKupon/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }


  const _getAuthData2 = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getJobSearchKupon/select/`)
      .then((res) => {
        setJob(res.data)
      })
  }
  const _getAuthData3 = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getCompanyKuponSearch/select/`)
      .then((res) => {
        setCompany(res.data)
      })
  }
   const _getAuthData4 = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getUsersGenderKupon/select/`)
      .then((res) => {
        setCategoryGender(res.data)
      })
  }


  const [getCategoryCompany, setCategoryCompany] = useState([]);

  const _getAuthDataCompany = async () => {

    await axios.get(`https://kuponifypremium.com.tr:8099/getCompanyKupon/select/`)
      .then((res) => {
        setCategoryCompany(res.data)
      })
  }

  useEffect(() => {
    _getAuthDataCompany()
    _getAuthData()
    _getAuthData2()
    _getAuthData3()
    _getAuthData4()
    if (users && !users.length) {
      dispatch(onGetUsers(null));
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    _getCity()
    // setIsEdit(false);
  }, []);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleLocation = () => {
    setModalLocation(!modalLocation);
  };


  const _setChoiceCityLoading  = async(arg) => {


    setListDisct([])

    
    let filterData = getListCity.filter((x)=> x.iso2 == arg)

        
    await axios.get(`https://kuponifypremium.com.tr:8099/getDistrict/TR/${arg}`)
    .then((res) => {
      res.data.map((item, index) => (

        
        setListDisct(oldArray => [...oldArray, item] )

        //console.log("lsdfkl",item)

      ))
       })

  }

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);


    
    setCategoryID(user.Users_Type_ID)
    setCategoryGenderID(user.GenderID)
    setJobID(user.Job_ID)
    setCompanyID(user.Company_ID)
    setFileName(user.UsersPhoto)
   
      setIsActive(user.Users_IsActive)
   
  
      setIsVip(user.Vip_Users)
   
  
      setIsOfficial(user.Company_Official)

    
      setSelectCity(user.City)

      setChoicegetDist(user.District)
      setCityID(user.City_ID)
  
      _setChoiceCityLoading(user.City_ID)
  
      setDistID(user.Dist_ID)
  

    setIsEdit(true);


    toggle();
  };


  const handleUserClickLocation = async(arg) => {
    const user = arg;



await axios.get(`https://kuponifypremium.com.tr:8099/getAddressKupon/select/${arg.ID}`)
.then((res) => {
  console.log("lşmsdşf",res.data)
if(res.data.length == 0){
  showToast(false)
}else{
  setLocaitonDetail(res.data)
  toggleLocation();

}


   })
  
  };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoicegetDist = (arg) => {

    setDistID(arg.target.value)
    let filterData = getListDisct.filter((x)=> x.id == arg.target.value)
    setChoicegetDist(filterData[0].name)

  };

  

  
  const _setChoiceCity  = async(arg) => {
    setListDisct([])

    setCityID(arg.target.value)

    let filterData = getListCity.filter((x)=> x.iso2 == arg.target.value)

    setSelectCity(filterData[0].name)
        
    await axios.get(`https://kuponifypremium.com.tr:8099/getDistrict/TR/${arg.target.value}`)
    .then((res) => {
      res.data.map((item, index) => (
        setListDisct(oldArray => [...oldArray, item] )
      ))
       })

  }

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }
  const _setChoiceCategoryGender = arg => {
    setCategoryGenderID(arg.target.value)
  }


  const _setChoiceJob = arg => {
    setJobID(arg.target.value)
  }


  const _setChoiceCompany = arg => {
    setCompanyID(arg.target.value)
  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const _getCity= async (city,CN) => {
    try {
  
       await axios.get(`https://kuponifypremium.com.tr:8099/getCity/TR`)
    .then((res) => {
  
      let newCityList = res.data.sort(function (a, b) {
        var atitle = a.name;
        var btitle = b.name;
        var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
        if (atitle.length === 0 || btitle.length === 0) {
            return atitle.length - btitle.length;
        }
        for (var i = 0; i < atitle.length && i < btitle.length; i++) {
            var ai = alfabe.indexOf(atitle[i].toUpperCase());
            var bi = alfabe.indexOf(btitle[i].toUpperCase());
            if (ai !== bi) {
                return ai - bi;
            }
        }
      })
  
      newCityList.map((item, index) => (
      
        setListCity(oldArray => [...oldArray, item] )

      ))
       })
       
      } catch (err) {
        console.log("_getCity",err)
      }
  }



  const [getExcelModal, setExcelModal] = useState(false)
  const [getType, setType] = useState(false);

  

  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"


    
    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const exportToCSVDontLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "Son1AyGirisYapmayanlar"


    const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
    elt.NameSurname,
    moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
      ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }


  const exportToCSVLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    console.log("lksdklf")
    let fileName = "Son1AyGirisYapanlar"

    const data2 = users.filter(x => x.Last_Login_Date >= getSelectDate && x.Last_Login_Date <= getSelectDateFinish).map(elt => [
    elt.NameSurname,
    moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
      ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
      
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const exportToCSVLogin = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"

  const data2 =  users.filter(x=> x.Last_Login_Date != null).map(elt=> [
    elt.NameSurname,
    moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
      ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const filterLogin = () => {
    const filteredUsers = users.filter(user => user.Last_Login_Date !== null)
   // const users = filteredUsers;
   setFilterCheck(true)
   setFilterData(filteredUsers)        
  }

  const filterDontLogin = () => {
    const filteredUsers = users.filter(user => user.Last_Login_Date == null)
    
    setFilterCheck(true)
   setFilterData(filteredUsers)        
  }

  const data2 =  users.map(elt=> [
    elt.NameSurname,
    elt.Last_Login_Date,
    ]);



  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Last_Login_Date <= moment().add(1, 'months').format('YYYY/MM/DD') && x.Company_ID)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Last_Login_Date <=! moment().add(1, 'months').format('YYYY/MM/DD') && x.Company_ID)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 



  }

  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getDateClick, setDateClick] = useState(null);

  const onClickExcelLogin = (order) => {
    console.log("lnsdnlkf")
    setDateClick(order);
    setExcelModal(true);
    setType('login')
  }

  const onClickExcelDontLogin = (order) => {
    console.log("lnsdnlkf")
    setDateClick(order);
    setExcelModal(true);
    setType('dontlogin')

  }


  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }


  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);


  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Kullanıcının girdiği metne göre seçenekleri filtrele
    const filtered = getCategoryCompany.filter(option =>
        option.Company_Title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredOptions(filtered);
};


const handleSelectChangeCompany = (arg) => {
  setCompanyID(arg.target.value)
}

const showToast= async (type) => {
  let ele = null
  let message = "";

   if(type == true){
     ele = "success"
     message = "Başarıyla güncellendi."
  } else{
     ele = "error"
     message = "Konumu bulunmamaktadır"
  };
  const position = ["toast-top-right"];
  let toastType;
  const title = "Başlık";


  //Close Button
  const closeButton = true;

  //Debug
  const debug = false;

  //Progressbar
  const progressBar = true;

  //Duplicates
  const preventDuplicates = false;

  //Newest on Top
  const newestOnTop = true;

  //position class
  let positionClass = "toast-top-right";

  //Show Easing
  const showEasing = "swing";

  //Hide Easing
  const hideEasing = "linear";

  //show method
  const showMethod = "fadeIn";

  //Hide method
  const hideMethod = "fadeOut";

  //show duration
  const showDuration = 300;

  //Hide duration
  const hideDuration = 1000;

  //timeout
  const timeOut = 5000;

  //extended timeout
  const extendedTimeOut = 1000;

  //Fetch checked Type
 
   toastType = ele;
  

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: closeButton,
    debug: debug,
    progressBar: progressBar,
    preventDuplicates: preventDuplicates,
    newestOnTop: newestOnTop,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration
  };

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (toastType === "info") toastr.info(message, title);
  else if (toastType === "warning") toastr.warning(message, title);
  else if (toastType === "error") toastr.error(message, title);
  else toastr.success(message, title);
}

  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Listesi" />

          <ExportToExcel2 title={"Giriş Yapmayanlar"} onDownloadClick={exportToCSV} onClickExcel={exportToCSV} apiData={data2} fileName={"fileName"} />
          <ExportToExcel2 title={"Giriş Yapanlar"} onDownloadClick={exportToCSVLogin} onClickExcel={exportToCSVLogin} apiData={data2} fileName={"fileName"} />
          <ExportToExcel2 title={"Giriş Yapmayanlar Filtrele"} onDownloadClick={exportToCSVDontLoginLastMonth} onClickExcel={onClickExcelDontLogin} apiData={data2} fileName={"fileName"} />
          <ExportToExcel2 title={"Giriş Yapanlar Filtrele"} onDownloadClick={exportToCSVLoginLastMonth} onClickExcel={onClickExcelLogin} apiData={data2} fileName={"fileName"} />


<FilterModal
handleSelectChangeCompany= {_onFilterClick}
       
        show={getFilterModal}
        getCategoryCompany={getCategoryCompany}
        onCloseClick={() => setFilterModal(false)}
     
      />


{getFilterCheckModal == false ? 
<Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterModal(true)}
              >
                <i className="mdi mdi-plus me-1" />
                Firmaya Göre Giriş Yapanlar
              </Button>:
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheckModal(false)}
              >
                <i className="mdi mdi-plus me-1" />
                Listeyi Sıfırla
              </Button>}
         
          
          {getFilterCheck == true ?  <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheck(false)}
              >
                <i className="mdi mdi-plus me-1" />
                Listeyi Sıfırla
              </Button> : 
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={filterLogin}
              >
                <i className="mdi mdi-plus me-1" />
                Giriş Yapanları Listele
              </Button>}
         
      
              {getFilterCheck == true ?    
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheck(false)}
              >
                <i className="mdi mdi-plus me-1" />
                Listeyi Sıfırla
              </Button>:
              <Button
              type="button"
              color="danger"
              className="btn-rounded  mb-2 me-2"
              onClick={filterDontLogin}
            >
              <i className="mdi mdi-plus me-1" />
              Giriş Yapmayanları Listele
            </Button>}
         
         

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={getFilterCheckModal == true ? getFilterData : getFilterCheck == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddUser={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
               
               <Modal  size="lg" isOpen={modalLocation} toggle={toggleLocation}>
                    <ModalHeader toggle={toggleLocation} tag="h4">
                      {!!isEdit ? "Düzenle" : "Konum Detay"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                      
                        <Col lg={6}>

                        {getLocaitonDetail.map((item, index) => (
                          <div key={index}>
                                                              <Label className="form-label">{item.CustomerAddressTitle} - </Label>
                                                              <Label className="form-label">{item.City} / </Label>
                                                              <Label className="form-label">{item.Dist} - </Label>

                                  <Label className="form-label"> {item.CustomerAddressText}</Label>
                                  </div>
                                ))}
                         </Col>
                        </Row>
                        
                      </Form>
                    </ModalBody>
                  </Modal>

                  <Modal  size="lg" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                      
                        <Col lg={6}>
                            
                          
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı İsim Soyisim</Label>
                              <Input
                                name="username"
                                label="username"
                                type="text"
                                placeholder="Kullanıcı İsim Soyisim Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            
                            <Col lg={6}>
                            <div className="mb-3">
                      <Label className="form-label">Kullanıcı Doğum Tarihi</Label>
                      <Input
                        name="birthday"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.birthday || ""}
                        invalid={
                          validation.touched.birthday && validation.errors.birthday ? true : false
                        }
                      />
                      {validation.touched.birthday && validation.errors.birthday ? (
                        <FormFeedback type="invalid">{validation.errors.birthday}</FormFeedback>
                      ) : null}
                   
                    </div>
                    </Col>

                    <Col lg={6}>

                    <div className="mb-3">
                              <Label className="form-label">Şehir Seçiniz</Label>
                              <Input
                                name="selectCity"
                                type="select"
                                className="form-select"
                                defaultValue={getCityID}
                                onChange={(text) => _setChoiceCity(text)}
                                value={
                                  getCityID
                                }>
                                {getListCity.map((item, index) => (
                                  <option value={item.iso2} key={item.name}>{item.name}</option>
                                ))}

                              </Input>
                           
                            </div>

                          
                            </Col>

                           { getSelectCity != null ? 
                            <Col lg={6}> 
                            <div className="mb-3">
                              <Label className="form-label">Semt Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getDistID}
                                onChange={(text) => _setChoicegetDist(text)}
                                value={
                                  getDistID
                                }>
                                {getListDisct.map((item, index) => (
                                  <option value={item.id} key={item.id}>{item.name}</option>
                                ))}

                              </Input>
                           
                            </div>


                         </Col> : null}


                            <div className="mb-3">
                              <Label className="form-label">Adres</Label>
                              <Input
                                name="address"
                                label="address"
                                type="text"
                                placeholder="Adres"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                    validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                                validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Email Adresi</Label>
                              <Input
                                name="email"
                                label="email"
                                type="text"
                                placeholder="Email Adresi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <Col lg={6}>
                          
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Tipi Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                <option value="">Lütfen Seçim Yapınız</option>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Type_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>

                            <div className="mb-3">
                              <Label className="form-label">Cinsiyet Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryGenderID}
                                onChange={(text) => _setChoiceCategoryGender(text)}
                                value={
                                  getCategoryGenderID
                                }>
                                 <option value="">Lütfen Seçim Yapınız</option>
                                {getCategoryGender.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.GenderType}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            </Col>


                            <Col lg={4}>
                            <div className="d-flex">
                              <label htmlFor="resume">Kullanıcı Firma Yetkilisi Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch3"
                                  className="switch"
                                  defaultChecked={getIsOfficial}
                                  onChange={() =>
                                    setIsOfficial(!getIsOfficial)
                                  }
                                />
                                <label
                                  htmlFor="square-switch3"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                            </Col>

                          <Col lg={4}>
                          <div className="mb-3">
                              <Label className="form-label">Firma Seçiniz</Label>

                              <input
                type="text"
                className="form-control"
                placeholder="Arama yapın..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCompanyID}
                               
                                onChange={(text) => handleSelectChangeCompany(text)}
                                value={
                                  getCompanyID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>

                                {filteredOptions.length > 0 ? filteredOptions.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          )) :  getCategoryCompany.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col> 

                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Meslek Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getJobID}
                                onChange={(text) => _setChoiceJob(text)}
                                value={
                                  getJobID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>
                                {getjob.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Job_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            </Col>


                            
                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Şifre</Label>
                              <Input
                                name="password"
                                label="password"
                                type="text"
                                placeholder="Kullanıcı Şifresi"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Telefon</Label>
                              <Input
                                name="tel"
                                label="tel"
                                type="text"
                                placeholder="Kullanıcı Telefon"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tel || ""}
                                invalid={
                                  validation.touched.tel &&
                                    validation.errors.tel
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tel &&
                                validation.errors.tel ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tel}
                                </FormFeedback>
                              ) : null}
                            </div>
                           </Col>

                           <Col lg={6}>
                            <div className="d-flex">
                              <label htmlFor="resume">Kullanıcı Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                            </Col>
                            
                            <Col lg={6}>
                            <div className="d-flex">
                              <label htmlFor="resume">Kullanıcı Vip Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch2"
                                  className="switch"
                                  defaultChecked={getIsVip}
                                  onChange={() =>
                                    setIsVip(!getIsVip)
                                  }
                                />
                                <label
                                  htmlFor="square-switch2"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                            </Col>

                    
                         
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
